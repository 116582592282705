loadStimulus(document);

application.register("lib", class extends Stimulus.Controller {
    ripple(e) {
        LibRipple(e.currentTarget, e)
    }

    anchor(e) {
        e.preventDefault();
        LibAnchor.action(e.currentTarget);
    }
});

application.register("lib-dialog", class extends Stimulus.Controller {
    connect() {
        if(typeof this.element.dataset.libDialogOpen !== "undefined") {
            let url = this.element.getAttribute("data-action-url");

            LibDialog.action(this.element, url, ()=>loadStimulus(document.querySelector(".lib--dialog")) );
        }
    }
    show(e) {
        let element = e.currentTarget;

        if (element === window) {
            element = document.querySelector(`[data-action="DOMContentLoaded@window->lib-dialog#show"]`);
        }

        let url = element.getAttribute("data-action-url");

        LibDialog.action(element, url, ()=>loadStimulus(document.querySelector(".lib--dialog")) );
    }

    hide() {
        LibDialog.hide(loadStimulus(document.querySelector(".lib--dialog")));
    }
});

application.register("lib-tabs", class extends Stimulus.Controller {
    connect() {
        LibTabs(this.element);
    }
});

application.register("lib-autosize", class extends Stimulus.Controller {
    connect() {
        importScript(cdnjs.autosize,()=>{
            autosize(this.element);
            this.element.addEventListener('autosize:resized', function(){
                if(typeof locomotive !== "undefined") {
                    locomotive.update();
                }
            });
        });
    }
});

application.register("lib-slider", class extends Stimulus.Controller {
    connect() {
        let self = this, element = self.element;
        inView(element,()=>{
            LibSlider(element, JSON.parse(self.data.get("options")));
        })
    }
});

application.register("lib-gallery", class extends Stimulus.Controller {
    connect() {
        let self = this, element = self.element;

        inView(element,()=>{
            LibGallery(element, self.data.get("id"));
        });
    }
});

application.register("lib-slideshow", class extends Stimulus.Controller {
    connect() {
        let self = this, element = self.element;

        inView(element,()=>{
            LibSlideshow(element);
        });
    }
});

application.register("lib-overimage", class extends Stimulus.Controller {
    connect() {
        cssLoaded(()=> {
            if (document.documentElement.classList.contains("no-touch")) {
                LibOverImage(this.element, this.data.get("id"));
            }
        });
    }
});

application.register("lib-tippy", class extends Stimulus.Controller {
    connect() {
        let element = this.element, options = this.data.get("options");
        bodyLoaded(() => {
            if (importScript.used.indexOf(cdnjs.tippy) === -1) {
                setTimeout(() => {
                    importStyle(cdnjs.tippy_css);
                    importScript([cdnjs.popper, cdnjs.tippy], () => {
                        LibTippy(element, options);
                    });
                }, 3000);
            } else {
                LibTippy(element, options);
            }
        })
    }
});
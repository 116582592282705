var swup;
if (typeof Swup !== "undefined") {
    if(window.location.hash !== "") {
    } else {
        swup = new Swup({
            containers: ['#layout_main', '#layout_header', '#layout_footer', '#part_update_banner', '#layout_menu', '#layout_nav', '#layout_social', '#layout_dialog'],
            linkSelector: 'a[href^="' + window.location.origin + '"]:not([data-no-swup]):not([target="_blank"]):not([download]), a[href^="/"]:not([data-no-swup]):not([target="_blank"]):not([download]), a[href^="#"]:not([data-no-swup]):not([target="_blank"]):not([download])',
        });
        let swup_timeout;

        swup.on('clickLink', function () {
            document.body.classList.remove("overflow--hidden");

            application["getControllerForElementAndIdentifier"](document.body, "lib-drawer").hide();
            clearTimeout(swup_timeout);

            if (document.querySelector(".lib--dialog") !== null) {
                LibDialog.hide();
            }
        });

        swup.on('contentReplaced', function () {
            if(typeof locomotive !== "undefined") {
                locomotive.destroy();
                document.getElementById("layout_scroll").removeAttribute("style");
                if(document.querySelector("#booking-engine") === null) {
                    locomotive.init();
                    locomotive.on("scroll",(e)=>{
                        currentScrollY = e.delta.y;
                        onScroll();
                    });
                }
            }
            window.scrollTo(0, 0);

            swup_timeout = setTimeout(()=>{
                document.documentElement.classList.remove("is--header-not-top", "is--header-pinned", "is--header-unpinned");
            },50);

            swup.options.containers.forEach((selector) => {
                loadStimulus(document.querySelector(selector));
            });

            LibAnchor.init();

            if (typeof fbq !== "undefined") {
                fbq('track', 'PageView');
            }

            if (typeof gtag !== "undefined") {
                let configs = [];
                window.dataLayer.forEach(function (config) {
                    if (config[0] === "config") {
                        if (typeof config[1] !== "undefined" && !configs.includes(config[1])) {
                            configs.push(config[1]);
                            gtag('config', config[1], {
                                'page_title': document.title,
                                'page_path': window.location.pathname + window.location.search
                            });
                        }
                    }
                });
            }

            if (typeof window.dataLayer !== "undefined") {
                window.dataLayer.push({
                    'event': 'VirtualPageview',
                    'virtualPageURL': window.location.pathname + window.location.search,
                    'virtualPageTitle': document.title
                });
            }

            if (typeof window.seznam_retargeting_id !== "undefined") {
                importScript("https://c.imedia.cz/js/retargeting.js");
            }

            if (typeof LibCookieConsent !== "undefined") {
                LibCookieConsent.init();
            }

            document.body.classList.remove("body--preload");
            document.body.classList.add("body--loaded");
        });
    }
}

const LibOverImage = function (element, id) {
    let timeout,
        overImage = element.querySelector(`#${id}`);

    let style;

    let existingStyle = document.getElementById("overImageStyle")
    if(existingStyle === null) {
        style = document.createElement('style');
        style.setAttribute("id","overImageStyle");
        document.head.appendChild(style);
    }
    else {
        style = existingStyle;
    }

    element.addEventListener("mousemove", (e) => {
        if (typeof overImage !== "undefined") {
            style.innerHTML = ""
            style.appendChild(document.createTextNode(`#${id} {left: ${Math.floor(e.x) + "px"}; top: ${Math.floor(e.y + locomotive.scroll.instance.scroll.y) + "px"};}`));
        }
    }, false);

    element.addEventListener("mouseenter", (e) => {
        if (e.target.dataset["libOverImageItem"]) {
            let image = document.getElementById(e.target.dataset["libOverImageItem"]);
            clearTimeout(timeout);
            if (image) {
                for (let elm of overImage.querySelectorAll(".part_ui_image")) {
                    elm.classList.remove('state--active')
                }
                image.classList.add("state--active");
            }
        }
    }, true);

    element.addEventListener("mouseleave", (e) => {
        if (e.target.dataset["libOverImageItem"]) {
            timeout = setTimeout(function () {
                for (let elm of overImage.querySelectorAll(".part_ui_image")) {
                    elm.classList.remove('state--active')
                }
            }, 100);
        }
    }, true);
};
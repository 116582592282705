const LibSlideshow = function (element) {
    importScript(cdnjs.flickity, ()=> {
        cssLoaded(()=> {

            // https://codepen.io/cmalven/pen/vqbJPM

            //options
            let tickerSpeed = (window.innerWidth < 960) ? 0.5 : 1;
            let flkty = null;
            let isPaused = false;

            //functions
            const update = () => {
                if (isPaused) return;
                if (flkty.slides) {
                    flkty.x = (flkty.x - tickerSpeed) % flkty.slideableWidth;
                    flkty.selectedIndex = flkty.dragEndRestingSelect();
                    flkty.updateSelectedSlide();
                    flkty.settle(flkty.x);
                }
                window.requestAnimationFrame(update);
            };

            // const pause = () => {
            //     isPaused = true;
            // };
            //
            // const play = () => {
            //     if (isPaused) {
            //         isPaused = false;
            //         window.requestAnimationFrame(update);
            //     }
            // };

            //create flickity
            flkty = new Flickity(element, {
                percentPosition: false,
                autoPlay: false,
                prevNextButtons: false,
                pageDots: false,
                draggable: false,
                accessibility: false,
                wrapAround: true,
                selectedAttraction: 0.015,
                friction: 0.25
            });
            flkty.x = 0;

            //eventListeners
            // element.addEventListener('mouseenter', pause, false);
            // element.addEventListener('focusin', pause, false);
            // element.addEventListener('mouseleave', play, false);
            // element.addEventListener('focusout', play, false);
            //
            // flkty.on('dragStart', () => {
            //     isPaused = true;
            // });

            window.addEventListener("resize",()=> {
                tickerSpeed = (window.innerWidth < 960) ? 0.5 : 1;
            });

            //start
            update();
        });
    });
};
application.register("comp_page_head_tabs", class extends Stimulus.Controller {
    get ref() {
        return {
            nav: this.targets.find("nav")
        }
    }
    connect() {
        this.ref.nav.scrollLeft = this.ref.nav.querySelector(".state--active").offsetLeft;
    }
    changeActive(event) {
        let self = this, element = self.element, target = event.target;

        if(target.classList.contains("state--active")) return;

        Array.from(this.ref.nav.querySelectorAll(".elm_tabs_item")).forEach((elm)=>{
            elm.classList.remove("state--active");
        })
        target.classList.add("state--active");
    }
});
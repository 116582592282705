application.register("comp_cloud", class extends Stimulus.Controller {
    connect() {
        this.generateLines();

        window.addEventListener("resize",()=> {
            this.generateLines();
        });
    }
    generateLines() {
        let body = this.element.querySelectorAll(".wrp_comp_body");

        Array.from(body).forEach((elm)=>{
            let cols = elm.children;

            Array.from(cols).forEach((col, i) => {
                cols[i].classList.remove("is--last");
                if(i > 0) {
                    let j = i -1;
                    if(cols[j].offsetTop < cols[i].offsetTop) {
                        cols[j].classList.add("is--last");
                    }
                }
            });
        })
    }
});
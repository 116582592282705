application.register("comp_visual", class extends Stimulus.Controller {
    get ref() {
        return {
            video: this.targets.find("video"),
            soundToggle: this.targets.find("soundToggle"),
        }
    }

    connect() {
        if(typeof this.ref.video !== "undefined") {
            this.ref.video.addEventListener('canplay', () => {
                if(!(this.ref.video.mozHasAudio || Boolean(this.ref.video.webkitAudioDecodedByteCount) || Boolean(this.ref.video.audioTracks && this.ref.video.audioTracks.length))) {
                    this.ref.soundToggle.remove()
                } else {
                    this.ref.soundToggle.classList.remove('display--none')
                }
            }, {once: true})
        }
    }

    toggleSound() {
        arguments[0].preventDefault()

        if(this.ref.video.muted) {
            this.element.classList.add('is-video-sound')
            this.ref.video.muted = false
        } else {
            this.element.classList.remove('is-video-sound')
            this.ref.video.muted = true
        }
    }

    scrollToNext() {
        LibAnchor.animation(this.element.nextElementSibling);
    }
});

application.register("layout_header", class extends Stimulus.Controller {
    connect() {
        const url = window.location.pathname
        const page = this.element.querySelector(`[href="${url}"]`)

        if(page === null) {
            return
        }

        const pageNext = page.nextElementSibling

        if(pageNext === null) {
            return
        }

        const customSubmenu = pageNext.querySelector(".elm_sub_custom")

        if(customSubmenu === null) {
            return;
        }

        const links = [...customSubmenu.querySelectorAll('a[href*="#"]')];

        links.forEach(link => {
            let regex = /#[^\s/]+/g;
            link.href = link.href.match(regex)
            link.setAttribute('data-action','click->lib#anchor')
        })
    }
});

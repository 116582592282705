application.register("lib-ajax", class extends Stimulus.Controller {
    link(e) {
        e.preventDefault();

        let element = e.currentTarget;

        // fetch(e.currentTarget.getAttribute("href")).then((response) => {
        //     return response.json();
        // }).then((payload) => {
        //     fn_ajaxHandler(payload);
        // })
        console.log(e, e.target.getAttribute("href"));

        importScript(cdnjs.jquery, () => {
            $.ajax({
                url: element.getAttribute("href"),
                data: {ajax: +new Date},
                dataType: "json",
            }).done(function (payload) {
                fn_ajaxHandler(payload);
            });
        })
    }

    linkComponent(e) {
        e.preventDefault();

        let url = e.currentTarget.getAttribute('data-href');

        if (!url || !url.length) {
            url = e.currentTarget.getAttribute('href');
        }

        if (!url || !url.length) {
            console.log('no href attribute for ajax component link');
            return;
        }

        // fetch(url).then((response) => {
        //     return response.json();
        // }).then((payload) => {
        //     fn_ajaxHandler(payload, function () {
        //         if (typeof payload["dialog"] !== 'undefined' && payload["dialog"].length) {
        //             LibDialog.show(payload["dialog"]);
        //         }
        //     });
        // });

        importScript(cdnjs.jquery, () => {
            $.ajax({
                url: url,
                method: 'post',
                dataType: 'json'
            }).done(function (payload) {
                fn_ajaxHandler(payload, function () {
                    if (typeof payload.dialog !== 'undefined' && payload.dialog.length) {
                        LibDialog.show(payload.dialog);
                    }
                });
            });
        })
    }

    form (e) {
        e.preventDefault();

        let frm = e.currentTarget,
            formData = new FormData(frm);

        // fetch(e.currentTarget.getAttribute("action")).then((response) => {
        //     return response.json();
        // }).then((payload) => {
        //     fn_ajaxHandler(payload, function () {
        //         if (typeof payload["dialog"] !== 'undefined' && payload["dialog"].length) {
        //             LibDialog.show(payload["dialog"]);
        //         }
        //     });
        // }).then(() => {
        //     frm.find('button[type=submit]').removeClass('state--loading').attr('disabled', false);
        // });

        frm.querySelector('button[type=submit]').classList.add('state--loading');

        importScript(cdnjs.jquery, () => {
            $.ajax({
                method: frm.getAttribute('method'),
                url: frm.getAttribute('action'),
                data: formData,
                processData: false,
                contentType: false,
                dataType: "json",
            }).done(function (payload) {
                fn_ajaxHandler(payload, function () {
                    if (typeof payload.dialog !== 'undefined' && payload.dialog.length && !payload.payload.dialogOpen) {
                        LibDialog.show(payload.dialog, ()=>{
                            loadStimulus(document.querySelector(".lib--dialog"))
                        });
                    }
                });
            }).always(function () {
                frm.querySelector('button[type=submit]').classList.remove('state--loading');
            });
        })
    }
});
application.register("layout_nav", class extends Stimulus.Controller {
    connect() {
        let links = [];
        Array.from(this.element.querySelectorAll(".elm_nav_inner")).forEach((nav)=>{
            Array.from(nav.querySelectorAll(".elm_nav_item")).forEach((link)=>{
                links.push(link);
            });
        })

        Array.from(links).forEach((link, index)=>{
            link.style.transitionDelay = `${0.2*(index+1)}s`;
        });
    }
    reset() {
        let navs = this.element.querySelectorAll(".elm_nav_inner");
        let subs = this.element.querySelectorAll("[data-sub-id]");

        Array.from(navs).forEach((nav)=> {
            nav.classList.remove("state--changing","state--hidden");
        })
        Array.from(subs).forEach((nav)=> {
            nav.classList.remove("state--changing","state--active");
        })
    }
    showSub(event) {
        let target = event.target;
        let id = target.getAttribute("data-action-id");
        let menu_wrappers = [];
        let submenu = this.element.querySelector(`[data-sub-id="${id}"]`);
        let timeout;

        if(window.outerWidth < 960) {
            Array.from(this.element.querySelectorAll(".elm_nav_inner")).forEach((elm)=>{
                menu_wrappers.push(elm);
            })
        }
        else {
            menu_wrappers.push(target.closest(".elm_nav_inner"));
        }

        Array.from(menu_wrappers).forEach((menu_wrapper)=>{
            clearTimeout(timeout);
            menu_wrapper.classList.add("state--changing", "state--hidden");
            timeout = setTimeout(()=>{
                menu_wrapper.classList.remove("state--changing");
                submenu.classList.add("state--active","state--changing");
            },300);
        });
    }

    hideSub(event) {
        let target = event.target;
        let submenu = target.closest("[data-sub-id]");
        let menu_wrappers = [];

        if(window.outerWidth < 960) {
            Array.from(this.element.querySelectorAll(".elm_nav_inner")).forEach((elm)=>{
                menu_wrappers.push(elm);
            })
        }
        else {
            menu_wrappers.push(submenu.closest(".elm_nav_menu").querySelector(".elm_nav_inner"));
        }

        Array.from(menu_wrappers).forEach((menu_wrapper)=>{
            let timeout;
            let timeout2;
            clearTimeout(timeout);
            submenu.classList.add("state--changing");
            submenu.classList.remove("state--active");
            timeout = setTimeout(()=>{
                clearTimeout(timeout2);
                submenu.classList.remove("state--changing");
                menu_wrapper.classList.add("state--changing");
                menu_wrapper.classList.remove("state--hidden");
                timeout2 = setTimeout(()=>{
                    menu_wrapper.classList.remove("state--changing");
                },300);
            },300);
        });

    }
});
const LibSlider = function (element, options) {
    importScript(cdnjs.flickity, ()=> {
        cssLoaded(()=> {
            let items = element.childElementCount;
            if(items >= options["minItems"] || options["minItems"] === "undefined") {
                let flkty = new Flickity( element, {
                    cellAlign: (typeof options["cellAlign"] !== "undefined") ? options["cellAlign"] : "left",
                    groupCells: (typeof options["groupCells"] !== "undefined") ? options["groupCells"] : false,
                    setGallerySize: true,
                    autoPlay: (typeof options["autoPlay"] !== "undefined") ? options["autoPlay"] : false,
                    pageDots: true,
                    wrapAround: (typeof options["wrapAround"] !== "undefined") ? options["wrapAround"] : true,
                    contain: true,
                    adaptiveHeight: false,
                    pauseAutoPlayOnHover: true,
                    prevNextButtons: true,
                    percentPosition: false,
                    watchCSS: (typeof options["watchCSS"] !== "undefined") ? options["watchCSS"] : false,
                });

                if(typeof options["flex"] !== "undefined") {
                    element.classList.add("flickity-flex");

                    window.addEventListener("resize",()=> {
                        if (typeof options["flex"] !== undefined) {
                            element.classList.remove("flickity-flex");
                            flkty.resize();
                            element.classList.add("flickity-flex");
                        }
                    });
                }

                if(typeof options["parallax"] !== "undefined") {
                    let imgs = element.querySelectorAll('.elm_slider_item .elm_item_image');
                    // get transform property
                    let docStyle = document.documentElement.style;
                    let transformProp = typeof docStyle.transform == 'string' ? 'transform' : 'WebkitTransform';

                    flkty.on( "scroll", ()=> {
                        flkty.slides.forEach( ( slide, i )=> {
                            let img = imgs[i];
                            let x = ( slide.target + flkty.x ) * -1/3;
                            img.style[ transformProp ] = 'translateX(' + x  + 'px)';
                        });
                    });
                }

                if(typeof options["counter"] !== "undefined") {
                    let ref = element.closest("[data-lib-slider-ref]");
                    if(ref !== null) {
                        let counter = ref.querySelector("[data-lib-slider-counter]");
                        if(counter !== null) {
                            let captions = [];

                            Array.from(element.querySelectorAll('.elm_slider_item .elm_item_image')).forEach((elm)=>{
                                let video = elm.querySelector("video");
                                let image = elm.querySelector("img");
                                if(video !== null) {
                                    captions.push(video.getAttribute("title"));
                                }
                                else if(image !== null) {
                                    captions.push(image.getAttribute("alt"));
                                }
                                else {
                                    captions.push("")
                                }
                            });
                            flkty.on("change",(i)=>{
                                counter.innerHTML = `${i+1}/${items}&nbsp;${captions[i]}`
                            });
                        }
                    }
                }

                flkty.on("change",(index)=>{
                    let video = flkty.slides[index].cells[0].element.querySelector("video");
                    if(video !== null) {
                        let promise = video.play();
                        if (promise !== undefined) {
                            promise.then(_ => {

                            }).catch(error => {

                            });
                        }

                    }
                })
            }
        });
    });
};
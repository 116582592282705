application.register("comp_form", class extends Stimulus.Controller {
    change(e) {
        let form = this.element;
        let select = form.querySelector('#f-select-js');
        let url = select.options[select.selectedIndex].dataset.url;
        let type = select.options[select.selectedIndex].value;

        importScript(cdnjs.jquery, () => {
            $.ajax({
                method: 'GET',
                url: url,
                data: {type: type},
                dataType: "json",
            }).done(function (payload) {
                fn_ajaxHandler(payload, function () {
                    if (typeof payload.dialog !== 'undefined' && payload.dialog.length && !payload.payload.dialogOpen) {
                        LibDialog.show(payload.dialog, ()=>{
                            loadStimulus(document.querySelector(".lib--dialog"))
                        });
                    }
                });
            });
        })
    }
});
let dynamicControllers = ["part_ui_input", "part_ui_select", "part_ui_wsw", "part_cookieconsent", "part_form_cookieconsent"];
let dynamicActions = [[`a[href^="#"]`, "click->lib#anchor"], [`.part_ui_btn, .part_ui_link`, "click->lib#ripple"]];
let locomotive;
let onScroll, setHeaderState, updateScroll, createLocomotive;
let currentScrollY = 0;

const application = Stimulus.Application.start(document.documentElement, {
    controllerAttribute: "data-controller",
    actionAttribute: "data-action",
    targetAttribute: "data-target"
});

importScript.used = [];
importScript.loaded = [];

if ('serviceWorker' in navigator && location.protocol === "https:") {
    window.addEventListener('load', function () {
        if (!document.documentElement.classList.contains("no-sw")) {
            navigator.serviceWorker.register('/sw.js').catch(function (e) {
                console.error('Error during service worker registration:', e);
            });
        } else {
            navigator.serviceWorker.getRegistrations().then(function (registrations) {
                if (registrations.length > 0) {
                    for (let registration of registrations) {
                        registration.unregister()
                    }
                }
            });
        }
    });
}

(function locomotiveScroll() {
    cssLoaded(function() {

        let latestKnownScrollY = 0;
        let ticking = false;
        let offsetTop = 5;

        createLocomotive = function () {
            locomotive = new LocomotiveScroll({
                el: document.getElementById('layout_scroll'),
                smooth: true,
                getSpeed: true,
                getDirection: true,
                inertia: 0.9
                // smoothMobile: true
            });

            setTimeout(function () {
                if(document.querySelector("#bookolo-engine") !== null) {
                    locomotive.destroy();
                    document.getElementById("layout_scroll").removeAttribute("style");
                    return;
                }
                locomotive.update();
                const resizeObserver = new ResizeObserver(() => {
                    locomotive.update()
                })

                resizeObserver.observe(document.querySelector('#layout_scroll'))

                if(!locomotive.isMobile) {
                    locomotive.on("scroll",(e)=>{
                        currentScrollY = e.delta.y;
                        onScroll();
                    });
                }
                else {
                    window.addEventListener('scroll', ()=>{
                        currentScrollY = window.scrollY;
                        onScroll();
                    }, true);
                }
            },1000);
        }

        setHeaderState = function () {
            let visual = document.querySelector("#layout_main").children[0];
            let social = document.querySelector("#layout_social");

            if(visual !== null && visual.classList.contains("comp_visual")) {
                offsetTop = visual.clientHeight;
            }
            else {
                offsetTop = 5;
            }

            if (currentScrollY >= offsetTop) {
                document.documentElement.classList.add("is--header-not-top");

                if (currentScrollY > latestKnownScrollY){
                    document.documentElement.classList.remove("is--header-pinned");
                    document.documentElement.classList.add("is--header-unpinned");
                } else {
                    document.documentElement.classList.remove("is--header-unpinned");
                    document.documentElement.classList.add("is--header-pinned");
                }
            } else {
                document.documentElement.classList.remove("is--header-not-top", "is--header-pinned", "is--header-unpinned");
            }

            if(social !== null) {
                if (currentScrollY >= (offsetTop - window.innerHeight/2)) {
                    social.classList.add("is--not-top");
                } else {
                    social.classList.remove("is--not-top");
                }
            }

            latestKnownScrollY = currentScrollY;
        }

        updateScroll = function() {
            setHeaderState();
            ticking = false;
        }

        onScroll = function () {
            if(!ticking) {
                requestAnimationFrame(updateScroll);
                ticking = true;
            }
        }

        if(!document.documentElement.classList.contains("ie")) {
            if(window.location.hash !== "") {
                document.querySelectorAll('[data-scroll]').forEach(element => {
                    element.classList.add('is-inview')
                })

                document.documentElement.style.height = 'auto'
                document.body.style.height = 'auto'

                window.addEventListener('scroll', ()=>{
                    console.log('scroll', window.scrollY)
                    currentScrollY = window.scrollY;
                    onScroll();
                }, true);
            } else {
                createLocomotive()
            }
        }
        else {
            window.addEventListener('scroll', ()=>{
                currentScrollY = window.scrollY;
                onScroll();
            }, false);
        }
    },0);
})();

if (document.body.classList.contains("body--preload")) {
    cssLoaded(function () {
        document.body.classList.remove("body--preload");
        setTimeout(function () {
            document.body.classList.add("body--loaded");
        }, 300);
    });
}

if (typeof WebFontConfig === "undefined" && document.querySelector("[data-loadcss]") !== null) {
    if (typeof document.fonts !== "undefined") {
        document.fonts.ready.then(function () {
            document.documentElement.classList.add("wf-active");
        });

        setTimeout(() => {
            if (!document.documentElement.classList.contains("wf-active")) {
                document.documentElement.classList.add("wf-active");
            }
        }, 500);
    } else {
        document.documentElement.classList.add("wf-active");
    }
}

if (document.createElement('picture').toString().indexOf("HTMLPictureElement") === -1 && !document.documentElement.classList.contains("ie")) {
    importScript(cdnjs.picturefill);
}

if (typeof document.documentElement.style.grid !== 'string') {
    document.documentElement.classList.add("no-grid");
}

application.register("comp_image_parallax", class extends Stimulus.Controller {
    get ref() {
        return {
            scene: this.targets.find("scene")
        }
    }
    connect() {
        let self = this, element = self.element;

        inView(element,()=>{
            importScript(cdnjs.parallax,()=>{
                let params = null;
                if (window.outerWidth < 600) {
                    params = {
                        scalarX: 24,
                        scalarY: 24
                    };
                }
                let parallax = new Parallax(this.ref.scene,params);
            });
        })
    }
});
const cdnjs = {
    "picturefill": "https://cdn.jsdelivr.net/npm/picturefill@3.0.3/dist/picturefill.min.js",
    "recaptcha": "https://www.google.com/recaptcha/api.js?render={apikey}",
    "jquery": "https://cdn.jsdelivr.net/npm/jquery@3.5.0/dist/jquery.min.js",
    "flickity": "https://cdn.jsdelivr.net/npm/flickity@2.2.1/dist/flickity.pkgd.min.js",
    "popper": "https://cdn.jsdelivr.net/npm/@popperjs/core@2.4.4/dist/umd/popper.min.js",
    "tippy": "https://cdn.jsdelivr.net/npm/tippy.js@6.2.6/dist/tippy.umd.js",
    "tippy_css": "https://cdn.jsdelivr.net/npm/tippy.js@6.2.6/animations/shift-away.css",
    "autosize": "https://cdn.jsdelivr.net/npm/autosize@4.0.2/dist/autosize.min.js",
    "fancybox": "https://cdn.jsdelivr.net/npm/@fancyapps/fancybox@3.5.7/dist/jquery.fancybox.min.js",
    "fancybox_css": "https://cdn.jsdelivr.net/npm/@fancyapps/fancybox@3.5.7/dist/jquery.fancybox.min.css",
    "parallax": "https://cdn.jsdelivr.net/npm/parallax-js@3.1.0/dist/parallax.min.js",
    "googlemaps": "https://maps.googleapis.com/maps/api/js?key={apikey}",
    "datepicker": "https://cdn.jsdelivr.net/npm/air-datepicker@2.2.3/dist/js/datepicker.min.js",
    "datepicker_lang": "https://cdn.jsdelivr.net/npm/air-datepicker@2.2.3/dist/js/i18n/datepicker.cs.js",
    "datepicker_css": "https://cdn.jsdelivr.net/npm/air-datepicker@2.2.3/dist/css/datepicker.min.css"
};

application.register("layout_footer", class extends Stimulus.Controller {
    get ref() {
        return {
            contact: this.targets.find("contact"),
            contactBody: this.targets.find("contactBody")
        }
    }
    toggleContact() {
        let targetHeight = 0;

        if(!this.ref.contact.classList.contains("state--active")) {
            targetHeight = this.ref.contactBody.scrollHeight;
            this.ref.contact.classList.add("state--active");
        }
        else {
            this.ref.contact.classList.remove("state--active");
        }
        this.ref.contactBody.style.height = `${targetHeight}px`
        if(typeof locomotive !== "undefined") {
            setTimeout(()=>{
                locomotive.update();
            },500)
        }
    }
    scrollToTop() {
        LibAnchor.animation(document.getElementById("layout_main").children[0]);
    }
});
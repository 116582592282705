const LibAnchor = {
    animation: (element) => {
        let offset = 0;
        let attr = "data-offset";

        if (element.getAttribute(attr) !== null && window.innerWidth > 960) {
            if (isNaN(parseInt(element.getAttribute(attr)))) {
                offset = document.querySelector(element.getAttribute(attr)).offsetHeight;
            } else {
                offset = parseInt(element.getAttribute(attr));
            }
        }
        if(typeof locomotive !== "undefined") {
            locomotive.scrollTo(element,-offset);
        }
        else {
            document.documentElement.scroll({top: element.offsetTop - offset});
        }
    },
    action: (element) => {
        let href = element.getAttribute("href");
        let id = document.querySelector("[id=" + href.replace('#', '') + "]");
        let options = element.getAttribute("data-action-options");

        if (options === null) {
            options = "";
        }

        if (id !== null) {
            if (!(options.indexOf("mobile") > -1 && window.innerWidth > 960)) {

                LibAnchor.animation(id);

                if (options.indexOf("hash") > -1) {
                    window.location.hash = id;
                }
            }
        }
    },
    init: () => {
        let selector = [...document.querySelectorAll("[id]")];

        if (selector[0] !== null) {

            selector.forEach((element) => {
                if (window.location.hash && element.getAttribute("id") === window.location.hash.replace('#', '')) {
                    cssLoaded(function () {
                        if(typeof locomotive !== "undefined") {
                            locomotive.update();
                            window.dispatchEvent(new Event('resize'))
                        }
                        LibAnchor.animation(element);
                    }, 50);
                }
            });
        }
    }
};

LibAnchor.init();
application.register("lib-lazy-video", class extends Stimulus.Controller {
    connect() {
        this._observer = new IntersectionObserver(
            entries => {
                if (entries[0].isIntersecting) {
                    this._observer.disconnect()

                    this.element.preload = 'auto'

                    if (this.element.hasAttribute('data-autoplay')) {
                        this.element.play()
                    }
                }
            }, {
                rootMargin: '100px'
            }
        )

        this._observer.observe(this.element)
    }

    disconnect() {
        this._observer.disconnect()
    }
})

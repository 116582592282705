application.register("lib-datepicker", class extends Stimulus.Controller {
    get ref() {
        return {
            input: this.targets.find("input"),
            alt: this.targets.find("alt")
        }
    }
    connect() {
        let element = this.element;
        let lang = document.documentElement.getAttribute("lang");
        let input = this.ref.input;
        let alt = this.ref.alt;

        inView(element,()=> {
            importStyle(cdnjs.datepicker_css);
            importScript([cdnjs.jquery, cdnjs.datepicker, cdnjs.datepicker_lang.replace(".cs.",`.${lang}.`)], ()=>{
                $(input).datepicker({
                    language: lang,
                    position: "bottom center",
                    minDate: new Date(),
                    startDate: new Date(),
                    autoClose: true,
                    altField: $(alt),
                    altFieldDateFormat: 'yyyy-mm-dd',
                    onShow: function () {
                        document.querySelector(".datepickers-container").classList.add("state--active");
                        element.classList.add("state--valid");
                    },
                    onHide: function () {
                        document.querySelector(".datepickers-container").classList.remove("state--active");
                        if (input.value === "") {
                            element.classList.remove("state--valid");
                        }
                    },
                    onSelect: function() {
                        element.classList.add("state--valid");
                    }
                }).data('datepicker');
            });
        });
    }
});
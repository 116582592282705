application.register("comp_image", class extends Stimulus.Controller {
    get ref() {
        return {
            video: this.targets.find("video")
        }
    }
    connect() {
        let self = this, element = self.element;

        if(typeof this.ref.video !== "undefined") {
            let callback = (mutationsList, observer) => {
                for(let mutation of mutationsList) {
                    if(element.classList.contains("is-inview")) {
                        observer.disconnect();
                        this.ref.video.play();
                    }
                }
            }
            let observer = new MutationObserver(callback);
            observer.observe(element, { attributes: true, attributeFilter: ['class'] });
        }
    }
});

application.register("part_ui_video", class extends Stimulus.Controller {
    get ref() {
        return {
            elm: this.element,
            video: this.element.querySelector("video"),
        }
    }
    connect() {
        this.ref.video.addEventListener("ended",()=>{
            this.ref.elm.classList.remove("state--playing");
            this.ref.video.currentTime = 0;
            this.ref.video.removeAttribute("controls");
        });
        this.ref.video.addEventListener("pause",()=>{
            this.ref.video.removeAttribute("controls");
            this.ref.elm.classList.remove("state--playing");
        });
        this.ref.video.addEventListener("play",()=>{
            this.ref.video.setAttribute("controls",true);
            this.ref.elm.classList.add("state--playing");
        });
    }
    togglePlay() {
        if(this.ref.video.paused) {
            this.ref.video.play();
        }
        else {
            this.ref.video.pause();
        }
    }
});
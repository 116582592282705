application.register("part_update_banner", class extends Stimulus.Controller {
    connect() {
        let element = this.element;
        if(element.classList.contains("is--forced")) {
            element.classList.remove("is--forced");
            window.localStorage.removeItem("hideUpdateBanner");
        }
        cssLoaded(()=>{
            if(window.localStorage.getItem("hideUpdateBanner") !== "true") {
                element.classList.add("state--active");
            }
        },2000);
    }
    hide() {
        this.element.classList.add("state--hiding");
        this.element.classList.remove("state--active");
        window.localStorage.setItem("hideUpdateBanner","true");
        setTimeout(()=>{
            this.element.remove()
        },500)
    }
});
application.register("layout_aside", class extends Stimulus.Controller {
    get ref() {
        return {
            nav: this.targets.find("nav"),
            body: this.targets.find("body"),
        }
    }
    connect() {
        cssLoaded(()=>{
            if(typeof locomotive !== "undefined" && !locomotive.isMobile) {
                let sections = this.ref.body.querySelectorAll("[data-offset]");
                let offsets = {};
                let layout_offset = this.element.offsetTop;

                Array.from(sections).forEach((section)=>{
                    let offset = (typeof section.dataset.offset !== "undefined") ? parseInt(section.dataset.offset) : 0;
                    offsets[section.getAttribute("id")] = section.offsetTop + layout_offset - offset*2;
                });

                locomotive.on("scroll",(e)=>{
                    let position = e.delta.y;

                    for(let i in offsets) {
                        if(offsets[i] <= position) {
                            let active = this.ref.nav.querySelector(".state--active")
                            if(active !== null) {
                                active.classList.remove("state--active");
                            }
                            this.ref.nav.querySelector(`[href="#${i}"]`).classList.add("state--active");
                        }
                    }
                });
            }
        })
    }
});
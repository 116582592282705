application.register("part_ui_input", class extends Stimulus.Controller {
    connect() {
        let self = this, element = self.element;

        self.validateInput(element, false);

        element.addEventListener("change", () => {
            self.validateInput(element, true);
        });

        if (element.querySelector(`[type="number"]`) !== null) {
            element.querySelector(`[type="number"]`).addEventListener("keydown", (e) => {
                if ([46, 8, 9, 27, 13, 190].indexOf(e.keyCode) !== -1 || e.keyCode === 16 || (e.keyCode === 65 && e.ctrlKey === true) || (e.keyCode >= 35 && e.keyCode <= 39)) {
                    return;
                }

                if (((e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
                    e.preventDefault();
                }
            });
        }
    }

    validateInput(element, validate) {
        let input = element.querySelectorAll("input, textarea")[0];

        if (input.outerHTML.match(/(data-no-validate|readonly)/) === null && validate) {
            element.classList.remove("state--invalid", "state--valid", "state--active");

            if (input.checkValidity()) {
                element.classList.add("state--valid");
            } else {
                element.classList.add("state--invalid");
            }
        }

        if (input.value !== "") {
            element.classList.add("state--active");
        }
    }

    plus() {
        let input = this.element.querySelector("input"),
            num = parseInt(input.value) + parseInt(input.getAttribute('data-step'));

        if (num <= input.getAttribute('max')) {
            input.value = num;
            input.dispatchEvent(new Event('change'));
        }
    }

    minus() {
        let input = this.element.querySelector("input"),
            num = parseInt(input.value) - parseInt(input.getAttribute('data-step'));

        if (num >= input.getAttribute('min')) {
            input.value = num;
            input.dispatchEvent(new Event('change'));
        }
    }
});